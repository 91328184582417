var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-device-sidebar","visible":_vm.isAddNewDeviceSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-device-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.actionType == 0 ? " Add New Device" : _vm.actionType == 1 ? " Update Device" : "Delete Device")+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Device Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Device Name","label-for":"device-name"}},[_c('b-form-input',{attrs:{"id":"device-name","disabled":_vm.actionType==2,"autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"Device Name"},model:{value:(_vm.currentName),callback:function ($$v) {_vm.currentName=$$v},expression:"currentName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Device"}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"deviceId","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"deviceName","options":_vm.dataCollectionDevices,"disabled":_vm.actionType==2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var deviceName = ref.deviceName;
return [_c('span',[_vm._v(" "+_vm._s(deviceName))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.currentDCDevice),callback:function ($$v) {_vm.currentDCDevice=$$v},expression:"currentDCDevice"}})],1),_c('b-form-group',{attrs:{"label":"WorkCenter"}},[_c('treeselect',{staticClass:"select-size-lg",attrs:{"id":"workCenterId","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.workCenters,"disabled":_vm.actionType==2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.currentWorkCenter),callback:function ($$v) {_vm.currentWorkCenter=$$v},expression:"currentWorkCenter"}})],1),_c('b-form-group',{attrs:{"label":"Additional Info","label-for":"additional-info"}},[_c('b-form-input',{attrs:{"id":"additional-info","disabled":_vm.actionType==2,"autofocus":"","trim":"","placeholder":"Additional Info"},model:{value:(_vm.currentAdditionalInfo),callback:function ($$v) {_vm.currentAdditionalInfo=$$v},expression:"currentAdditionalInfo"}})],1),_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"disabled":_vm.actionType==2,"id":"input-isActive"},model:{value:(_vm.currentIsActive),callback:function ($$v) {_vm.currentIsActive=$$v},expression:"currentIsActive"}},[_vm._v(" IsActive ")]),_c('div',{staticClass:"d-flex mt-2"},[(_vm.actionType != 2)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.currentDevice==null|| _vm.currentDevice.id==null? 'Add':'Update')+" ")]):_vm._e(),(_vm.currentDevice!=null&& _vm.currentDevice.id!=null && _vm.actionType == 2)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"type":"button","variant":"outline-danger"},on:{"click":_vm.deleteDevice}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }