<template>
  <b-sidebar
    id="add-new-device-sidebar"
    :visible="isAddNewDeviceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-device-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Device" : actionType == 1 ? " Update Device" : "Delete Device"}}
         
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Device Name"
            rules="required"
          >
            <b-form-group
              label="Device Name"
              label-for="device-name"
            >
              <b-form-input
                id="device-name"
                v-model="currentName"
                :disabled="actionType==2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Device Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>

              <b-form-group label="Device">
                  <v-select
                          id="deviceId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          v-model="currentDCDevice"
                          label="deviceName"
                          :options="dataCollectionDevices"
                          :disabled="actionType==2"
                          class="select-size-lg">
                  <template #option="{ id, deviceName }">
                      <span> {{ deviceName }}</span><br />
                      <small class="text-muted"> {{ id }} </small>
                  </template>
                  </v-select>
              </b-form-group>

              <b-form-group label="WorkCenter">
                <treeselect
                        id="workCenterId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="currentWorkCenter"
                        label="name"
                        :options="workCenters"
                        :disabled="actionType==2"
                        class="select-size-lg">
                <template #option="{ id, name }">
                    <span> {{ name }}</span><br />
                    <small class="text-muted"> {{ id }} </small>
                </template>
                </treeselect>
            </b-form-group>


              <b-form-group
              label="Additional Info"
              label-for="additional-info"
            >
              <b-form-input
                id="additional-info"
                v-model="currentAdditionalInfo"
                :disabled="actionType==2"
                autofocus
                trim
                placeholder="Additional Info"
              />
              </b-form-group>

              <!-- <WorkcenterParentChild ref='componentFileUpload' @fileResult="getFileResult"> </WorkcenterParentChild> -->

              <b-form-checkbox 
                class="mt-2"
                :disabled="actionType==2"
                id="input-isActive"
                v-model="currentIsActive"
              >
                IsActive
              </b-form-checkbox>
     
          <!-- <category-selector :initial-id="currentCategory==null||currentCategory.upperCategory==null?null:currentCategory.upperCategory"  @selectionChanged="selectionChanged" ></category-selector> -->
       
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-if="actionType != 2"
              class="mr-2"
              type="submit"
            >
             {{ currentDevice==null|| currentDevice.id==null? 'Add':'Update'}} 
            </b-button>
             <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              v-if="currentDevice!=null&& currentDevice.id!=null && actionType == 2"
              variant="outline-danger"
              @click="deleteDevice"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {inject,toRefs, ref,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    Treeselect
  },
  created() {
    this.GetDataColectionDevices();
    this.fetchWorkCenters();
  },
   methods:{
     removeEmptyChildren(node) {
      if (node.children.length === 0) {
        delete node.children;
      } else {
        node.children.forEach(child => this.removeEmptyChildren(child));
      }
    },
    formatParentChildForTreeSelect(data) {
     const idToData = {};
      const rootItems = [];

      data.forEach(item => {
        const { id, name, parentID } = item;
        const newItem = { id, label: name, children: [] };

        idToData[id] = newItem;

        if (parentID === 0) {
          rootItems.push(newItem);
        } else {
          const parentItem = idToData[parentID];
          if (parentItem) {
            if (!parentItem.children) {
              parentItem.children = [];
            }
            parentItem.children.push(newItem);
          }
        }
      });

      return rootItems;
    },
    fetchWorkCenters() {
      this.workCenters = [];
      this.$store.dispatch('pamis-device/fetchWorkCenters')
        .then((result) => {
          console.log(result)
          if (result != null) {
            const workCenterList = result.data.filter(x =>x.isDelete == false && x.isActive == true)
            var list = this.formatParentChildForTreeSelect(workCenterList);
            list.forEach(node => this.removeEmptyChildren(node));

            this.workCenters = list
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Workcenter By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    GetDataColectionDevices() {
      this.$store.dispatch('pamis-device/fetchDataCollectionDevices')
        .then((result) => {
          if (result != null) {
            var deviceList = result.data.filter(x => x.isDelete == false && x.isActive == true)
            this.dataCollectionDevices = deviceList
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Data Colection Devices By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    deleteDevice(){
       this.$store.dispatch('pamis-device/deleteDevice', {id : this.currentDevice.id})
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-device-sidebar-active', false)
          this.notification()
        });
    },

    submitForm(){
      if (this.currentDCDevice == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Device Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return false;
      }

      if (this.currentWorkCenter == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'WorkCenter can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return false;
      }
      
      var process = "pamis-device/addDevice";

      if(this.currentDevice !=null &&  this.currentDevice.id != null){
        process = "pamis-device/updateDevice";
      } 

      this.currentDevice.name = this.currentName;
      this.currentDevice.deviceGUID = this.currentDCDevice.id;
      this.currentDevice.workCenterID = this.currentWorkCenter;
      this.currentDevice.additionalInfo = this.currentAdditionalInfo;
      this.currentDevice.isActive = this.currentIsActive;
        
      this.$store.dispatch(process, this.currentDevice)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-device-sidebar-active', false)
          this.notification()
        });

    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },
  
  watch: {
     devicedata:   {
        handler:function (n, o)   {
          if (n==null||n==undefined||n.selectedDevice.deviceGUID ==null)
          {
            this.currentDevice={
              Id:null,
              name:null,
              deviceGUID:null,
              workCenterID:null,
              additionalInfo:null,
              isActive:true
            }
            this.currentDCDevice = null;
            this.currentWorkCenter = null;
            this.currentName = null;
            this.currentAdditionalInfo = null;
            this.currentIsActive = true;
          } else
          { 
            this.currentName = n.selectedDevice.name;
            this.currentDevice = n.selectedDevice;
            this.currentAdditionalInfo = n.selectedDevice.additionalInfo;

            if(n.selectedDevice.deviceGUID != null){
              this.currentDCDevice = this.dataCollectionDevices.find(x=>x.id == n.selectedDevice.deviceGUID);
            } else {
              this.currentDCDevice = this.dataCollectionDevices.find(x=>x.id == this.currentDCDevice.id);
            }

            if (n.selectedDevice.workCenterID != null) {
              this.currentWorkCenter = n.selectedDevice.workCenterID;
            } else {
              this.currentWorkCenter = this.currentWorkCenter.id;
            }

            this.currentIsActive = n.selectedDevice.isActive;
          }
        },
        deep: true
    }
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDeviceSidebarActive',
    event: 'update:is-add-new-device-sidebar-active',
  },
  props: {
    isAddNewDeviceSidebarActive: {
      type: Boolean,
      required: true,
    },
     actionType: {
      type: Number,
      required: true,
    },
  },
   
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      dataCollectionDevices : [],
      currentName : null,
      currentDCDevice : null,
      workCenters : [],
      currentWorkCenter : null,
      currentAdditionalInfo : null,
      currentIsActive : true,
      currentDevice:{
        Id:null,
        Name:null,
        deviceGUID:null,
        workCenterID:null,
        additionalInfo:null,
        isActive:true,
      },
    }
  },
   
  inject: ['devicedata'],

  setup(props,  { emit }) {
    const blankDeviceData = {
      id:null,
      name:null,
      deviceGUID:null,
      workCenterID:null,
      additionalInfo:null,
    }

    const deviceData = ref(JSON.parse(JSON.stringify(blankDeviceData)))
    const resetDeviceData = () => {
      deviceData.value = JSON.parse(JSON.stringify(blankDeviceData))
      emit('refetch-data')
      emit('update:is-add-new-device-sidebar-active', false)
    }
      
    const onSubmit = () => {
      store.dispatch('pamis-device/addDevice', currentDevice)
        .then(() => {
        })
    }
     
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDeviceData)

    return {
      deviceData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-device-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
