import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDeviceList() {
  // Use toast
  const toast = useToast()
  const refDeviceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: true },
    { key: 'device', label: 'DC Device Name', sortable: true },
    { key: 'workCenter', sortable: true },
    { key: 'additionalInfo', sortable: false },
    { key: 'isActive' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalDevices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const dataCollectionDevices = ref([])
  const search = ref('')
  const workcenters = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refDeviceListTable.value ? refDeviceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalDevices.value,
    }
  })

  const refetchData = () => {
    refDeviceListTable.value.refresh()
    GetData()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })

  function GetWorkCenters() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-device/fetchWorkCenters')
      .then(response => {
        var workCenterDeviceResult = [];

        if(response.data != null && response.data.length > 0){
          workCenterDeviceResult = response.data.filter(x=>x.isDelete == false && x.isActive == true);
          resolve(workCenterDeviceResult);
          workcenters.value = workCenterDeviceResult;
        } else {
          reject(workCenterDeviceResult)
        }
      })
      .catch(() => {
        reject(workCenterDeviceResult)
      })
    })
  }

  function GetDevices() {
    store.dispatch('pamis-device/fetchDevices')
      .then(response => {
        const deviceResult = response.data.filter(x=>x.isDelete == false);
        row.value = deviceResult;
        totalDevices.value = deviceResult.length;

        row.value.forEach(element => {
          var devicelist = dataCollectionDevices.value.filter(x => x.id == element.deviceGUID);

          if(devicelist != null && devicelist.length > 0){
            element.device = devicelist[0].deviceName
          }
          
          var workcenterlist = workcenters.value.filter(x => x.id == element.workCenterID);

          if(workcenterlist != null && workcenterlist.length > 0){
            element.workCenter = workcenterlist[0].name
          }
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Device list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  function GetDataColectionDevices() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-device/fetchDataCollectionDevices')
      .then(response => {
        var deviceResult = [];

        if(response.data != null && response.data.length > 0){
          deviceResult = response.data.filter(x=>x.isDelete == false && x.isActive == true);
          resolve(deviceResult);
          dataCollectionDevices.value = deviceResult;
        } else {
          reject(deviceResult)
        }
      })
      .catch(() => {
        reject(deviceResult)
      })
    })
  }

  async function GetData(){
    await GetDataColectionDevices();
    await GetWorkCenters();
    GetDevices();
  }

  GetData()


  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalDevices,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refDeviceListTable,
    refetchData
  }
}
