import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWorkCenters(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/WorkCenter')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
  },
    fetchDevices(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/device/device')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchDataCollectionDevices(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/dc-wizard/Device/Devices')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    addDevice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/device/device/Create',  data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDevice(ctx, { id }) {
       return new Promise((resolve, reject) => {
         axios
           .delete('pamis/admin/device/device/Delete?id='+id)
           .then(response => resolve(response))
           .catch(error => reject(error))
       })
     },
     updateDevice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/device/device/Update?id='+data.id, data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
